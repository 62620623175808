<template>
  <li class="overflow-hidden rounded-xl border border-gray-200">
    <div class="items-center gap-x-4 border-b border-gray-900/5 bg-gray-200 p-6">
      <div>
        <span class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 m-b-0.5">
          <a v-if="order.status === 'open'">Offene Bestellung</a>
          <a v-if="order.status === 'ready'">Zur Abholung bereit</a>
          <a v-if="order.status === 'fulfilled'">Abgeholt</a>
        </span>
      </div>
      <div class="f-right">
        <span :style="color" @click="expand = !expand" class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium text-gray-900">
          {{ order.status === 'open' ? timer.isoString : duration.string + ' (' + order.created.split(' ')[1] + ' - ' + order.delivered.split(' ')[1] + ')' }}
        </span>
      </div>
      <div class="text-l font-medium leading-6 text-gray-900">
        Abholcode: <span class="font-bold">#{{order.pickUpCode}}</span>
      </div>
      <div class="text-sm font-medium leading-6 text-gray-900 -mt-2">{{ order.firstname }} {{ order.lastname }} </div>
    </div>
    <dl class="-my-3 divide-y divide-gray-100 py-4 text-sm leading-6">
      <div class="flex justify-between gap-x-4 py-3 px-6" v-for="item in order.items.drinks" :key="item.id">
        <dt class="text-gray-600">
          {{ item.name }} ( <span class="font-bold">{{ item.amount + item.unit }}</span> )
          <div class="text-xs text-gray-400">{{ item.price.toFixed(2) }}€</div>
        </dt>
        <dd class="text-gray-700 font-bold">{{ item.quantity }}x</dd>
      </div>
      <div class="block pt-3 bg-slate-100 px-6" v-if="order.status !== 'fulfilled'">
        <span v-if="order.paymentMethod === 'AppPay'" class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 m-b-0.5">Bereits per App bezahlt</span>
        <span v-else class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 m-b-0.5">Muss noch bezahlt werden</span>
      </div>
      <div v-else class="block pt-3 bg-slate-100 px-6">
        <span v-if="order.paymentMethod === 'AppPay'" class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 m-b-0.5">Per App bezahlt</span>
        <span v-else class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 m-b-0.5">Bar bezahlt</span>
      </div>
      <div class="flex justify-between gap-x-4 pb-3 bg-slate-100 px-6">
        <dt class="text-gray-500">Gesamt:</dt>
        <dd class="text-right items-start gap-x-2 text-sm font-bold text-gray-900">
          <div>{{ order.subTotal.toFixed(2) }}€</div>
          <div class="font-normal text-xs" v-if="order.items.tip[0]?.price.toFixed(2) > 0" >Trinkgeld: +{{ order.items.tip[0]?.price.toFixed(2) }}€</div>
        </dd>
      </div>
      <div v-if="!loading" class="gap-x-4 py-3 bg-slate-100 px-6">
        <button v-if="order.status === 'open'" @click="fulfill('ready')" class="w-full items-center justify-center rounded-lg px-4 py-2 text-sm bg-blue-600 text-white hover:bg-blue-700">
          Zur Abholung freigeben
        </button>
        <button v-if="order.status === 'ready'" @click="fulfill('fulfilled')" class="w-full items-center justify-center rounded-lg px-4 py-2 text-sm bg-blue-600 text-white hover:bg-blue-700">
          Bestellung wurde abgeholt
        </button>
      </div>
      <div v-else class="gap-x-4 py-3 bg-slate-100 px-6">
        <button disabled class="w-full items-center justify-center rounded-lg px-4 py-2 text-sm bg-gray-600 text-white ">
          Bitte warten
        </button>
      </div>
    </dl>
  </li>
</template>

<script>
import {configStore} from "@/store/configStore";
import {aSyncData, pad, stringToDate} from "@/modules/main";

export default {
  name: "orderComp",
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  emits: ['fulfill'],
  setup() {
    const config = configStore()

    return {config}
  },
  data(){
    return{
      expand: true,
      overDueValue: 450,
      loading: false,
    }
  },
  computed:{
    timer(){
      try {
        return this.config.calculateTimeString(stringToDate(this.order.created).getTime())
      }catch (e) {
        return {}
      }
    },
    color() {
      const halfOverDueValue = this.overDueValue / 2;
      const timer = this.order.status === 'open' ? this.timer.seconds : this.duration.seconds
      const red = timer >= halfOverDueValue ? 255 : Math.round(Math.min(255, Math.max(0, 255 - (halfOverDueValue- timer) / halfOverDueValue * 255)));
      const green = timer >= halfOverDueValue ? Math.round(Math.max(0, Math.min(255, 255 - ((timer - halfOverDueValue)) / halfOverDueValue * 255))) : 255;
      return {
        'background-color': `rgb(${red},${green},0)!important`
      };
    },
    duration() {
      let seconds = (stringToDate(this.order.delivered).getTime() - stringToDate(this.order.created).getTime())/1000
      let isoString
      if(seconds < 60*60){
        isoString = Math.floor(seconds/60) + ':' + pad(seconds % 60, 2)
      }else{
        isoString = Math.floor(seconds/3600) + ':' + pad(Math.floor(seconds % 3600 / 60), 2)  + ':' + pad(seconds % 60, 2)
      }
      return {string: isoString, seconds: seconds}
    }
  },
  methods: {
    fulfill(status){
      this.loading = true
      aSyncData('orders/fulfill', {orderId: this.order.id, status: status})
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.$emit('fulfill', this.order.id)
              this.loading = false
            }
          })
          .catch(e => {console.error(e)})
    }
  }
}
</script>

<style scoped>




.container{
  width: calc(100% - 8px);
  margin: 16px 0;
  border-radius: 20px;
  overflow: hidden;
}
.box{
  text-align: center;
  padding: 16px 10px;
  z-index: 2;
  position: relative;
}

.orderNameId{
  padding-left: 10px;
}

@media (max-width: 767px) {
  .orderNameId{
    text-align: center;
    padding: unset;
  }
}

.orderUserPriceInformations{
  align-items: center;
}

.expand{
  background-color: rgba(0,0,0,0.05);
  transition: all 0.5s ease;
  z-index: 1;
  overflow: hidden;
}
.expand > div{
  padding: 20px;
  position: relative;
}
.tip{

}
.subTotal{
  font-weight: bold;
  font-size: 1.2em;
}
.drink{

}
.drink > div{
  margin: 6px 0;
}
.overdue{
  /*box-shadow: 0 0 0 0 rgb(176, 7, 7);*/
  transform: scale(1);
  /*animation: pulse 2s infinite;*/
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: rgba(100, 22, 22, 0.7) 0 0 10px;
  }

  70% {
    transform: scale(1);
    box-shadow: rgba(100, 22, 22, 0.7) 0 0 10px;
  }

  100% {
    transform: scale(0.95);
    box-shadow: rgba(100, 22, 22, 0.7) 0 0 10px;
  }
}

.button-confirm{
  background-color: #2068ff;
  color: white;
  width: 100%;
  margin-top: 24px;
}

.overdue .confirm-button {
  display: inline-block; /* Der Button soll inline dargestellt werden */
  cursor: pointer; /* Mauszeiger wird zum Zeiger */
  animation-name: huer; /* Name der Animation */
  animation-duration: 0.6s; /* Dauer der Animation */
  animation-timing-function: ease-in-out; /* Art der Animation */
  animation-iteration-count: infinite; /* Anzahl der Wiederholungen */
}

@keyframes huer {
  0% { transform: translateY(0); } /* Startposition */
  50% { transform: translateY(-5px); } /* Zwischenposition */
  100% { transform: translateY(0); } /* Endposition */
}
</style>