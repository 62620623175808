<template>
  <main>
      <div class="max-w-xl m-auto">
        <nav aria-label="Progress" class="mx-auto flex">
          <ol role="list" class="inline-flex mx-auto items-center">
            <li class="pr-8 sm:pr-20 relative">
              <template v-if="step > 0">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-indigo-600" />
                </div>
                <a @click="step = 0" class="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900">
                  <CheckIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </a>
              </template>
              <template v-else-if="step === 0">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-gray-200" />
                </div>
                <a @click="step = 0" class="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white" aria-current="step">
                  <span class="h-2.5 w-2.5 rounded-full bg-indigo-600" aria-hidden="true" />
                </a>
              </template>
              <template v-else>
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-gray-200" />
                </div>
                <a class="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                  <span class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" aria-hidden="true" />
                </a>
              </template>
            </li>
            <li class="pr-8 sm:pr-20 relative">
              <template v-if="step > 1">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-indigo-600" />
                </div>
                <a @click="step = 1" class="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900">
                  <CheckIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </a>
              </template>
              <template v-else-if="step === 1">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-gray-200" />
                </div>
                <a @click="step = 1" class="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white" aria-current="step">
                  <span class="h-2.5 w-2.5 rounded-full bg-indigo-600" aria-hidden="true" />
                </a>
              </template>
              <template v-else>
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-gray-200" />
                </div>
                <a class="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                  <span class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" aria-hidden="true" />
                </a>
              </template>
            </li>
            <li class="pr-8 sm:pr-20 relative">
              <template v-if="step > 2">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-indigo-600" />
                </div>
                <a @click="step = 2" class="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900">
                  <CheckIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </a>
              </template>
              <template v-else-if="step === 2">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-gray-200" />
                </div>
                <a @click="step = 1" class="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white" aria-current="step">
                  <span class="h-2.5 w-2.5 rounded-full bg-indigo-600" aria-hidden="true" />
                </a>
              </template>
              <template v-else>
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-gray-200" />
                </div>
                <a class="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                  <span class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" aria-hidden="true" />
                </a>
              </template>
            </li>
            <li class="pr-8 sm:pr-20 relative">
              <template v-if="step > 3">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-indigo-600" />
                </div>
                <a @click="step = 3" class="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900">
                  <CheckIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </a>
              </template>
              <template v-else-if="step === 3">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-gray-200" />
                </div>
                <a @click="step = 3" class="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white" aria-current="step">
                  <span class="h-2.5 w-2.5 rounded-full bg-indigo-600" aria-hidden="true" />
                </a>
              </template>
              <template v-else>
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-gray-200" />
                </div>
                <a class="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                  <span class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" aria-hidden="true" />
                </a>
              </template>
            </li>
            <li class="pr-8 sm:pr-20 relative">
              <template v-if="step > 4">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-indigo-600" />
                </div>
                <a @click="step = 4" class="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900">
                  <CheckIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </a>
              </template>
              <template v-else-if="step === 4">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-gray-200" />
                </div>
                <a @click="step = 4" class="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white" aria-current="step">
                  <span class="h-2.5 w-2.5 rounded-full bg-indigo-600" aria-hidden="true" />
                </a>
              </template>
              <template v-else>
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-gray-200" />
                </div>
                <a class="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                  <span class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" aria-hidden="true" />
                </a>
              </template>
            </li>
            <li class="relative">
              <template v-if="step > 5">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-indigo-600" />
                </div>
                <a @click="step = 5" class="relative flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 hover:bg-indigo-900">
                  <CheckIcon class="h-5 w-5 text-white" aria-hidden="true" />
                </a>
              </template>
              <template v-else-if="step === 5">
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-gray-200" />
                </div>
                <a @click="step = 5" class="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white" aria-current="step">
                  <span class="h-2.5 w-2.5 rounded-full bg-indigo-600" aria-hidden="true" />
                </a>
              </template>
              <template v-else>
                <div class="absolute inset-0 flex items-center" aria-hidden="true">
                  <div class="h-0.5 w-full bg-gray-200" />
                </div>
                <a class="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                  <span class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" aria-hidden="true" />
                </a>
              </template>
            </li>
          </ol>
        </nav>
        <div class="px-6 md:px-0 text-center mt-4">
          <h3 class="text-sm font-medium text-gray-500 hover:text-gray-700">Registrierung</h3>
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">App4Clubs Demo</h2>
          <div v-if="step === 0" class="">
            <div class="bg-white rounded shadow p-4 mt-6">
              <h6 class="text-xs">Frage 1</h6>
              <h4 class="text-md font-medium">Wie dürfen wir dich ansprechen</h4>
              <div class="flex gap-x-2 items-center mt-6">
                <div class=" relative rounded-md shadow-sm w-full">
                  <label for="firstname" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-slate-900">Vorname</label>
                  <input v-model="firstname" placeholder="Vorname" required type="text" name="firstname" id="firstname" class="block bg-white w-full rounded-md border-0 py-1.5 pl-3 pr-2 text-black ring-gray-300 ring-inset border-gray-100 placeholder:text-gray-600 sm:text-sm sm:leading-6" />
                </div>
                <div class=" relative rounded-md shadow-sm w-full">
                  <label for="lastname" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-slate-900">Nachname</label>
                  <input v-model="lastname" placeholder="Nachname" required type="text" name="lastname" id="lastname" class="block bg-white w-full rounded-md border-0 py-1.5 pl-3 pr-2 text-black ring-gray-200 ring-inset border-gray-50 placeholder:text-gray-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
              <div class="flex justify-center mt-4">
                <button @click="checkName" class=" h-10 items-center justify-center rounded-lg px-12 text-sm sm:flex bg-blue-600 text-white hover:bg-blue-700"> Weiter </button>
              </div>
                <p v-if="firstname.length < 3 && lastname.length < 3" class="text-red-500 text-xs pt-1.5 pb-2 text-center">Der Vor- und Nachname muss jeweils mind. 2 Zeichen beinhalten</p>
            </div>
          </div>
          <div v-if="step === 1" class="">
            <div class="bg-white rounded shadow p-4 mt-6">
              <h6 class="text-xs">Frage 2</h6>
              <h4 class="text-md font-medium">Wie lautet deine E-Mail-Adresse</h4>
              <div class="mt-6">
                <div class=" relative rounded-md shadow-sm w-full">
                  <label for="email" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-slate-900">E-Mail-Adresse</label>
                  <input v-model="email" placeholder="E-Mail-Adresse" required type="email" name="email" id="email" class="block bg-white w-full rounded-md border-0 py-1.5 pl-3 pr-2 text-black ring-gray-200 ring-inset border-gray-50 placeholder:text-gray-600 sm:text-sm sm:leading-6" />
                </div>
                <p v-if="invalidEmail" class="text-red-500 text-xs pt-3 pb-2 text-center">Keine gültige E-Mail-Adresse.</p>
              </div>
              <div class="flex justify-center mt-4 gap-x-4">
                <button @click="step = 0" class="h-10 items-center justify-center rounded-lg px-12 text-sm sm:flex border-2 border-blue-600 text-blue-600"> Zurück </button>
                <button @click="checkEmail" class="h-10 items-center justify-center rounded-lg px-12 text-sm sm:flex bg-blue-600 text-white hover:bg-blue-700"> Weiter </button>
              </div>
            </div>
          </div>
          <div v-if="step === 2" class="">
            <div class="bg-white rounded shadow p-4 mt-6">
              <h6 class="text-xs">Frage 3</h6>
              <h4 class="text-md font-medium">Wie heißt dein Club oder Unternehmen?</h4>
              <div class="mt-4">
                <div class=" relative rounded-md shadow-sm w-full">
                  <label for="locationname" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-slate-900">Locationname</label>
                  <input v-model="clubname" placeholder="Name deiner Location" required type="text" name="locationname" id="locationname" class="block bg-white w-full rounded-md border-0 py-1.5 pl-3 pr-2 text-black ring-gray-200 ring-inset border-gray-50 placeholder:text-gray-600 sm:text-sm sm:leading-6" />
                </div>
                <p v-if="clubNameLength" class="text-red-500 text-xs pt-3 pb-2 text-center">Der Locationname muss mind. 2 Zeichen haben.</p>
              </div>
              <div class="flex justify-center mt-4 gap-x-4">
                <button @click="step = 1" class="h-10 items-center justify-center rounded-lg px-12 text-sm sm:flex border-2 border-blue-600 text-blue-600"> Zurück </button>
                <button @click="checkClubname" class="h-10 items-center justify-center rounded-lg px-12 text-sm sm:flex bg-blue-600 text-white hover:bg-blue-700"> Weiter </button>
              </div>
            </div>
          </div>
          <div v-if="step === 3" class="">
            <div class="bg-white rounded shadow p-4 mt-6">
              <h6 class="text-xs">Frage 4</h6>
              <h4 class="text-md font-medium">Wie lautet deine Telefonnummer</h4>
              <div class="mt-4">
                <div class=" relative rounded-md shadow-sm w-full">
                  <label for="phone" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-slate-900">Telefonnummer</label>
                  <input v-model="phone" placeholder="+49 1234 564789" required type="tel" name="phone" id="phone" class="block bg-white w-full rounded-md border-0 py-1.5 pl-3 pr-2 text-black ring-gray-200 ring-inset border-gray-50 placeholder:text-gray-600 sm:text-sm sm:leading-6" />
                </div>
                <p v-if="telLength" class="text-red-500 text-xs pt-3 pb-2 text-center">Die Telefonnummer ist zu kurz.</p>
              </div>
              <div class="flex justify-center mt-4 gap-x-4">
                <button @click="step = 2" class="h-10 items-center justify-center rounded-lg px-12 text-sm sm:flex border-2 border-blue-600 text-blue-600"> Zurück </button>
                <button @click="checkPhoneNumber" class="h-10 items-center justify-center rounded-lg px-12 text-sm sm:flex bg-blue-600 text-white hover:bg-blue-700"> Weiter </button>
              </div>
            </div>
          </div>
          <div v-if="step === 4" class="">
            <div class="bg-white rounded shadow p-4 mt-6">
              <h6 class="text-xs">Frage 5</h6>
              <h4 class="text-md font-medium">Lege ein Passwort fest</h4>
              <div class="mt-4">
                <div class=" relative rounded-md shadow-sm w-full">
                  <label for="password" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-slate-900">Passwort</label>
                  <input v-model="password" placeholder="*********" required type="password" name="password" id="password" class="block bg-white w-full rounded-md border-0 py-1.5 pl-3 pr-2 text-black ring-gray-200 ring-inset border-gray-50 placeholder:text-gray-600 sm:text-sm sm:leading-6" />
                  <p v-if="password.length < 3 && password.length > 0" class="text-red-500 text-xs pt-1.5 pb-2 ">Das Passwort muss mindestens 3 Zeichen enthalten.</p>
                </div>
                <div class="p-2"></div>
                <div class=" relative rounded-md shadow-sm w-full">
                  <label for="password2" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-slate-900">Passwort wiederholen</label>
                  <input v-model="passwordRequired" placeholder="*********" required type="password" name="password2" id="password2" class="block bg-white w-full rounded-md border-0 py-1.5 pl-3 pr-2 text-black ring-gray-200 ring-inset border-gray-50 placeholder:text-gray-600 sm:text-sm sm:leading-6" />
                </div>
              </div>
              <div class="flex justify-center mt-4 gap-x-4">
                <button @click="step = 3 " class="h-10 items-center justify-center rounded-lg px-12 text-sm sm:flex border-2 border-blue-600 text-blue-600"> Zurück </button>
                <button @click="checkPassword" class="h-10 items-center justify-center rounded-lg px-12 text-sm sm:flex bg-blue-600 text-white hover:bg-blue-700"> Weiter </button>
              </div>
                <p v-if="passwordsMismatch && password.length >= 3 && passwordRequired.length >= 3" class="text-red-500 text-xs pt-3 pb-2 text-center">Die Passwörter stimmen nicht überein.</p>
            </div>
          </div>
          <div v-if="step === 5" class="">
            <div class="bg-white rounded shadow p-4 mt-6">
              <h6 class="text-xs">Letzte Frage</h6>
              <h4 class="text-md font-medium">Rechtliches</h4>
              <div class="mt-4">
                <div class="flex gap-x-3 items-center">
                  <input v-model="privacy" type="checkbox" name="privacy" id="privacy">
                  <label class="text-sm" for="privacy">Ich habe die <router-link to="#" class="font-semibold">Datenschutzbestimmung</router-link> gelesen und akzeptiere diese.</label>
                </div>
                <div class="p-1"></div>
                <div class="flex gap-x-3 items-center">
                  <input v-model="agb" type="checkbox" name="agb" id="agb">
                  <label class="text-sm" for="agb">Ich habe die <router-link to="#" class="font-semibold">AGB</router-link> gelesen und akzeptiere diese.</label>
                </div>
              </div>
              <div class="flex justify-center mt-4 gap-x-4">
                <button @click="step = 4 " class="h-10 items-center justify-center rounded-lg px-12 text-sm sm:flex border-2 border-blue-600 text-blue-600"> Zurück </button>
                <button @click="checkAllLegals" class="h-10 items-center justify-center rounded-lg px-12 text-sm sm:flex bg-blue-600 text-white hover:bg-blue-700"> Abschicken </button>
              </div>
              <p v-if="notAllSelected" class="text-red-500 text-xs pt-3 pb-2 text-center">Bestätige bitte die Datenschutzerklärung sowie unsere ABG.</p>
            </div>
          </div>
        </div>
      </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {CheckIcon} from '@heroicons/vue/20/solid'


export default {
  name: "DemoRegister",
  title: "Demo Anmeldung",
  components: {
    CheckIcon
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      step: 0,
      firstname:'',
      lastname:'',
      email:'',
      cubName:'',
      phone:'',
      clubname:'',
      password:'',
      passwordRequired:'',
      passwordsMismatch: false,
      privacy: false,
      agb: false,
      notAllSelected: false,
      firstnameLength: false,
      lastnameLength: false,
      invalidEmail: false,
      telLength: false,
      clubNameLength: false,

    }
  },
  mounted() {
    this.config.loading = false
  },
  methods: {
    checkName() {
      if(this.firstname.length < 3 || this.lastname.length < 3 ) {
        this.firstnameLength = true;
        this.lastnameLength = true;
      } else {
        this.firstnameLength = false;
        this.lastnameLength = false;
        this.step = 1;
      }
    },

    checkEmail() {
      let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      this.invalidEmail = this.email.match(emailRegex) ? false : true;
      if(!this.invalidEmail) {
        this.step = 2;
      }
    },

    checkClubname() {
      if(this.clubname.length < 2 ) {
        this.clubNameLength = true;
      } else {
        this.clubNameLength = false;
        this.step = 3;
      }
    },

    checkPhoneNumber() {
      if(this.phone.length < 2 ) {
        this.telLength = true;
      } else{
        this.telLength = false;
        this.step = 4;
      }
    },

    checkPassword() {
      if(this.password.length < 3 || this.passwordRequired.length < 3) {

      } else if(this.password !== this.passwordRequired) {
        this.passwordsMismatch = true;
      } else {
        this.passwordsMismatch = false;
        this.step = 5;
      }
    },
    checkAllLegals() {
      if (this.privacy && this.agb) {
        this.notAllSelected = false;
        this.auth.register(this.email, this.phone, this.firstname, this.lastname, this.clubname, this.password)
            .then((response) => {
              if(response === 250){
                this.status = 'ok'
                this.$router.push('/dashboard')
              }else if(response === 251){
                this.status = 'email'
              }
            })
            .catch(() => {
              this.status = 'fail'
            })
      } else {
        this.notAllSelected = true;
      }
    }

  }
}
</script>

<style scoped>

</style>