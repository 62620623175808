<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/gaderobe" class="text-sm font-medium text-gray-500 hover:text-gray-700">Garderobe</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Digitale Garderobe</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              </div>
            </div>
          </div>
          <div>
            <div>
              <form>
                <div class="relative mt-4 mb-4">
                  <label for="eventId" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Event auswählen</label>
                  <select v-model="eventId" @change="startScanner(); scannerTools = true;" id="eventId" class="text-l px-2 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                    <option value="-1" disabled>
                      Event...
                    </option>
                    <option v-for="event in events" :key="event.id" :value="event.id">
                      {{ event.title + ' - ' + event.startTime.split(' ')[0].split('-')[2] + '.' + event.startTime.split(' ')[0].split('-')[1] }}
                    </option>
                  </select>
                </div>
              </form>
              <div v-if="eventId == -1" class="bg-blue-600 text-white p-10 rounded text-center">
                <ShoppingBagIcon class="h-20 w-20 flex-shrink-0 text-white " aria-hidden="true" style="margin: 0 auto;"/>
                <h5 style="font-size: 1.4em;">
                  Bitte wähle ein Event aus
                </h5>
              </div>
            </div>

            <div class="grid-12">
              <div class="col-sp-12 col-tb-6 row-sp-12">
                <section id="Scanner">
                  <transition name="scanned">
                    <div id="ticketChecked" class="overlay green" v-if="checkedAnimation">
                      <div style="padding-top: 25%;" class="t-center">
                        <div class="pad-8"></div>
                        <img src="assets/images/icons/check-white.svg" class="width-64 mx-auto">
                        <h5 style="font-size: 1.5em; margin-bottom: 8px; margin-top: 8px">
                          Garderobenmarke ist gültig
                        </h5>
                      </div>
                    </div>
                  </transition>
                  <transition name="error" id="error">
                    <div id="error" class="overlay error" v-if="error">
                      <div style="padding-top: 25%;" class="t-center">
                        <div class="pad-8"></div>
                        <img src="assets/images/icons/error-white.svg" class="width-64 mx-auto">
                        <h5 style="font-size: 1em; margin-bottom: 8px; margin-top: 8px">
                          {{ error }}
                        </h5>
                        <button @click="error = false; this.startScanner()" class="bgcolor-white color-black button">
                          Okay
                        </button>
                      </div>
                    </div>
                  </transition>
                  <div class="videoContainer">
                    <video ref="scanner" />
                  </div>
                </section>
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-12 ml-2">
                <section class="bg-white rounded shadow p-4" v-if="eventId != -1">
                  <div class="border-b border-gray-200 bg-white">
                    <h3 class="text-base text-xl font-semibold leading-6 text-gray-900">Garderoben Inventar</h3>
                  </div>
                  <div>
                    <ul role="list" class="mt-4">
                      <li v-for="gitems in gaderobeItems" :key="gitems.id" class="col-span-1 flex rounded-md shadow-sm mt-3">
                        <div class="bg-blue-700 flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white">
                          {{ gitems.pickupNumber }}
                        </div>
                        <div class="flex items-center justify-between truncate w-full rounded-r-md border-b border-r border-t border-gray-200 bg-slate-50">
                          <div class="px-4 py-2 text-sm">
                            <a class="font-bold text-gray-900 hover:text-gray-600" v-if="gitems.status == 0">Noch nicht abgegeben</a>
                            <p class="text-gray-500" v-if="gitems.status == 1">
                              Garderobe abgegenen am: <br>{{ covertTime(gitems.handoverTimestamp) }}
                            </p>
                            <p class="text-gray-500" v-else-if="gitems.status == 2">
                              Garderobe bereits abgeholt am: <br>{{ covertTime(gitems.pickupTimestamp) }}
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div v-if="!gaderobeItems.length">
                      <p class="py-4 text-gray-500" >
                        Keine Gegenstände in der Digitalen Gaderobe
                      </p>
                    </div>
                  </div>
                </section>
              </div>
              <div class="pad-40"></div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <TransitionRoot as="template" :show="setNumberModal">
      <Dialog as="div" class="relative z-10" @close="setNumberModal = false; startScanner()">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <form @submit.prevent="setNumber()">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="setNumberModal = false; startScanner()">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <QrCodeIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Garderoben-Nummer vergeben</DialogTitle>
                      <div class="mt-2">
                        <p class="text-sm text-gray-500">
                          Bitte gebe die Nummer ein, mit welcher du die Kleidung in der Garderobe hinterlegst.
                        </p>
                      </div>
                      <div>
                        <div class="relative mt-4">
                          <label for="pickupNumber" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Garderoben-Nummer festlegen:</label>
                          <input required type="number" v-model="pickupNumber" name="pickupNumber" id="pickupNumber" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Abholnummer" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Nummer vergeben</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="setNumberModal = false; startScanner()">Abbrechen</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <TransitionRoot as="template" :show="pickupModal">
      <Dialog as="div" class="relative z-10" @close="pickupModal = false; startScanner()">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <form @submit.prevent="pickupModal = false; startScanner()">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="pickupModal = false; startScanner()">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ShoppingBagIcon class="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Abholung</DialogTitle>
                      <div class="mt-2">
                        <p class="text-sm text-gray-500">
                          Bitte gebe die folgende Garderobennummer heraus:<br>
                          <b class="f-bold text-xl">{{ pickupId }}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">Erledigt</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
/* eslint-disable */
import QrScanner from 'qr-scanner';
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import { ChevronLeftIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon, ShoppingBagIcon  } from '@heroicons/vue/20/solid'
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: "gaderobe",
  title: "Gaderobe",
  components: {
    ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon,ArrowDownIcon, ArrowUpIcon,  BriefcaseIcon,
    CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon,
    Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon, ShoppingBagIcon
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      qrScanner: null,
      error: false,
      checkedAnimation: false,
      startAnimation: false,
      cancelAnimation: false,
      gaderobeItems: [],
      eventId: -1,
      events: [],
      scannerTools: false,
      scanTicketModal: false,
      gaderobeId: '',
      manualTicketScanError: false,
      setNumberModal: false,
      pickupNumber: '',
      pickupId: '',
      pickupModal: false
    }
  },
  watch: {
  },
  mounted() {
    this.initScanner()
    this.getEvents()
  },
  beforeUnmount() {
    try {
      this.qrScanner.stop()
      this.qrScanner.destroy();
      this.qrScanner = null;
    }catch (e) {}
  },
  methods:{
    async initScanner(){
      const _this = this
      return new Promise(resolve => {
        this.qrScanner = new QrScanner(
            this.$refs.scanner,
            _this.scanned,
            {
              highlightScanRegion: true,
              highlightCodeOutline: true
            })
        this.qrScanner.setInversionMode('both');
        resolve()
      })
    },
    startScanner(){
      const _this = this
      this.getGaderobeItems()
      this.qrScanner.start()
      setTimeout(() => {
        _this.loading = false
      }, 1000)
    },
    stopScanner(){
      try {
        this.qrScanner.stop()
      }catch (e) {}
    },
    scanned(r){
      this.stopScanner()
      const _this = this
      const fD = new FormData()

      fD.append('sessionID', this.auth.sessionID)
      fD.append('secureCode',  r.data)
      fD.append('eventId',  this.eventId)
      this.axios.post('garderobe/scan', fD)
          .then(r => {
            console.log(r)
            if(r.status === 250) {
              _this.checkedAnimation = true
              const successAudio = new Audio('assets/audio/success.mp3');
              successAudio.play();
              setTimeout(() => {
                _this.checkedAnimation = false
                _this.gaderobeId = r.data.id
                _this.setNumberModal = true
              }, 1000)
            }else if(r.status === 253) {
              _this.checkedAnimation = true
              const successAudio = new Audio('assets/audio/success.mp3');
              successAudio.play();
              setTimeout(() => {
                _this.checkedAnimation = false
                _this.pickupId = r.data.pickupNumber
                _this.pickupModal = true
              }, 1000)
            }
            else{
              _this.error = r.data
              const denyAudio = new Audio('assets/audio/deny.mp3');
              denyAudio.play();
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getEvents(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('organizerId',  this.config.organizerId)
      this.axios.post('garderobe/getEvents', fD)
          .then(r => {
            console.log(r)
            if(r.status === 250) {
              _this.events = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    setNumber(){
      this.manualTicketScanError = false
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.gaderobeId)
      fD.append('pickupNumber', this.pickupNumber)
      this.axios.post('garderobe/setNumber', fD)
          .then(r => {
            if(r.status === 250) {
              _this.setNumberModal = false;
              _this.gaderobeId = ''
              _this.pickupNumber = ''
              _this.startScanner()
            }else{
              _this.manualTicketScanError = true;
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    getGaderobeItems(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('eventId',  this.eventId)
      this.axios.post('garderobe/getGaderobeItems', fD)
          .then(r => {
            if(r.status === 250) {
              _this.gaderobeItems = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    covertTime(scanTimestamp){
      let timestampArr = scanTimestamp.split(" ");
      let date = timestampArr[0];
      let time = timestampArr[1];
      const dateArr = date.split("-");
      const timeArr = time.split(":");
      return dateArr[2]+'.'+dateArr[1]+'.'+dateArr[0]+' - '+timeArr[0]+':'+timeArr[1]+' Uhr';
    },
  },
}
</script>

<style scoped>
section#Scanner video{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
#Scanner{
  position: relative;
  min-height: 400px;
}
#Scanner #ticketChecked{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #ffffff;
  background-color: #00cc66;
  border-radius: 10px;
  z-index: 9;
}
#Scanner #error{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #ffffff;
  background-color: #BD081C;
  border-radius: 10px;
  z-index: 9;
}


@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

</style>