<template>
  <div class="px-4">
    <div class="mb-2">
      <div>
        <nav class="sm:hidden" aria-label="Back">
          <router-link to="/barmenu" class="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon class="-ml-1 mr-1 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Zurück
          </router-link>
        </nav>
        <nav class="hidden sm:flex" aria-label="Breadcrumb">
          <ol role="list" class="flex items-center space-x-4">
            <li>
              <div class="flex">
                <a href="/barmenu" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
              </div>
            </li>
            <li>
              <div class="flex items-center">
                <ChevronRightIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                <a class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">Barkarte bearbeiten</a>
              </div>
            </li>
          </ol>
        </nav>
      </div>
      <div class="mt-2 md:flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
          <h2 v-if="!editName" class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">{{ name }}</h2>
          <div v-else class="relative">
            <label for="tempName" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Name der Barkarte</label>
            <input @keyup.enter="changeName" type="text" v-model="tempName" name="tempName" id="tempName" class="block w-full rounded-md border-0 text-3xl font-bold py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Name der Barkarte" />
          </div>
        </div>
        <div v-if="editName" class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <button @click="editName = false"  type="button" class="h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Abbrechen
          </button>
          <button  @click="changeName" type="button" class="ml-4 h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-blue-600 font-semibold	 text-white hover:bg-blue-700">
            Speichern
          </button>
        </div>
        <div v-else class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
          <button @click="deleteModal = true" type="button" class="h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Barkarte löschen
          </button>
          <button @click="editName = true" type="button" class="ml-4 h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Umbenennen
          </button>
        </div>
      </div>
      <div class="bg-white p-4 rounded shadow mt-4">
        <div v-if="editDescription" class="relative">
          <label for="description" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung</label>
          <textarea @keyup.enter="changeDescription" rows="4" v-model="tempDescription" name="description" id="description" class="block w-full font-normal rounded-md border-0 text-sm font-bold py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600" placeholder="Beschreibung" />
        </div>
        <div v-else class="relative">
          <label class=" inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung</label>
          <p class="block w-full font-normal rounded-md border-0 text-sm font-bold py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600" >
            {{description}}
            <span v-if="!description">Keine Beschreibung vorhanden</span>
          </p>
        </div>
        <div class="mt-2 flex">
          <div class="min-w-0 flex-1"></div>
          <div v-if="editDescription" class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
            <button @click="editDescription = false"  type="button" class=" items-center justify-center rounded-lg px-4 py-1.5  text-sm sm:flex bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Abbrechen
            </button>
            <button  @click="changeDescription" type="button" class="ml-4 items-center justify-center rounded-lg px-4 py-1.5 text-sm sm:flex bg-blue-600 font-semibold	 text-white hover:bg-blue-700">
              Beschreibung Speichern
            </button>
          </div>
          <div v-else class="mt-4 flex flex-shrink-0 md:mt-0 ">
            <button @click="editDescription = true" type="button" class="items-center justify-center rounded-lg px-4 py-1.5 text-sm sm:flex bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Bearbeiten
            </button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="bg-white p-4 rounded shadow mt-4">
        <div class="categoriesEdit">
          <div class="grid-12">
            <div class="col-sp-12 row-sp-12">
              <div class="categoriesEditButton">
                <div class="relative">
                  <div class="mt-2 flex rounded-md shadow-sm">
                    <div class="relative flex flex-grow items-stretch focus-within:z-10">
                      <label for="categoryName" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Neuer Kategoriename</label>
                      <input @keyup.enter="addBarCategory" type="text" v-model="categoryName" name="categoryName" id="categoryName" class="block w-full rounded-md border-0 text-l px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6" placeholder="Kategoriename" />
                    </div>
                    <button @click="addBarCategory" type="button" class="relative inline-flex items-center rounded-r-md px-3 py-2 text-sm sm:flex bg-blue-600 font-semibold text-white hover:bg-blue-700">
                      Hinzufügen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <bar-category v-for="(category, index) in categories" :key="category.id" :index="index" :category="category" :quantity="categories.length" @edit-category="editCategory" @delete-category="deleteCategory" @list-order="listOrder" />
      </div>
      <div class="bg-white p-4 rounded shadow mt-4 mb-8">
        <div v-if="editIngredients" class="relative">
          <label for="ingredients" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Inhaltsstoffe</label>
          <textarea @keyup.enter="changeIngredients" rows="4" v-model="tempIngredients" name="ingredients" id="ingredients" class="block w-full font-normal rounded-md border-0 text-sm font-bold py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600" placeholder="Inhaltsstoffe" />
        </div>
        <div v-else class="relative">
          <label class=" inline-block bg-white px-1 text-xs font-medium text-gray-900">Inhaltsstoffe</label>
          <p class="block w-full font-normal rounded-md border-0 text-sm font-bold py-1.5 px-2 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600" >
            {{ingredients}}
            <span v-if="!ingredients">Keine Inhaltsstoffe vorhanden</span>
          </p>
        </div>
        <div class="mt-2 flex">
          <div class="min-w-0 flex-1"></div>
          <div v-if="editIngredients" class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
            <button @click="editIngredients = false"  type="button" class=" items-center justify-center rounded-lg px-4 py-1.5  text-sm sm:flex bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Abbrechen
            </button>
            <button  @click="changeIngredients" type="button" class="ml-4 items-center justify-center rounded-lg px-4 py-1.5 text-sm sm:flex bg-blue-600 font-semibold	 text-white hover:bg-blue-700">
              Inhaltsstoffe Speichern
            </button>
          </div>
          <div v-else class="mt-4 flex flex-shrink-0 md:mt-0 ">
            <button @click="editIngredients = true" type="button" class="items-center justify-center rounded-lg px-4 py-1.5 text-sm sm:flex bg-white font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              Bearbeiten
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TransitionRoot as="template" :show="deleteModal">
    <Dialog as="div" class="relative z-10" @close="deleteModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <form @submit.prevent="deleteBarMenu()">
                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="deleteModal = false">
                    <span class="sr-only">Close</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div class="sm:flex sm:items-start">
                  <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Barkarte löschen?</DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Bist du sicher, dass du die barkarte inkl. aller Getränke und Kategorien löschen möchtest?
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button type="submit" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto">Barkarte löschen</button>
                  <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="deleteModal = false">Abbrechen</button>
                </div>
              </form>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import BarCategory from "@/components/barMenu/barCategory";
import { ChevronLeftIcon, ChevronRightIcon, ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
export default {
  name: "barMenu",
  components: {BarCategory,ChevronLeftIcon, ChevronRightIcon,Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, ExclamationTriangleIcon, XMarkIcon },
  title: "Barkarte",
  props: {
    barMenuId: {
      type: Number,
      required: true
    }
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return{
      barMenues: [],
      editName: false,
      deleteModal: false,
      name: '',
      tempName: '',
      categories: [],
      categoryName: '',
      editDescription: false,
      description: '',
      tempDescription: '',
      tempIngredients: '',
      ingredients: '',
      editIngredients: false
    }
  },
  computed: {
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    getDetails(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barMenuId', this.barMenuId)
      this.axios.post('barMenu/getBarMenu', fD)
          .then(r => {
            if (r.status === 250) {
              _this.name = r.data.name
              _this.tempName = r.data.name
              _this.description = r.data.description
              _this.tempDescription = r.data.description
              _this.ingredients = r.data.ingredients
              _this.tempIngredients = r.data.ingredients
              _this.getCategories()
            }else{
              _this.$router.push({name: 'BarMenuOverview'})
            }
          })
    },
    getCategories(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barMenuId', this.barMenuId)
      this.axios.post('barMenu/getBarCategoriesByBarMenu', fD)
          .then(r => {
            if (r.status === 250) {
              _this.categories = r.data.sort((a, b) => {return a.listOrder - b.listOrder})
            }
          })
    },
    changeName() {
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barMenuId', this.barMenuId)
      fD.append('name', this.tempName)
      this.axios.post('barMenu/editBarMenu', fD)
          .then(r => {
            if (r.status === 250) {
              _this.name = r.data
              _this.tempName = r.data
              _this.editName = false
            }
          })
    },
    changeDescription() {
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barMenuId', this.barMenuId)
      fD.append('description', this.tempDescription)
      this.axios.post('barMenu/editDescriptionBarMenu', fD)
          .then(r => {
            console.log(r)
            if (r.status === 250) {
              _this.description = r.data
              _this.tempDescription = r.data
              _this.editDescription = false
            }
          })
    },
    changeIngredients() {
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barMenuId', this.barMenuId)
      fD.append('ingredients', this.tempIngredients)
      this.axios.post('barMenu/editIngredientsBarMenu', fD)
          .then(r => {
            console.log(r)
            if (r.status === 250) {
              _this.ingredients = r.data
              _this.tempIngredients = r.data
              _this.editIngredients = false
            }
          })
    },
    addBarCategory() {
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barMenuId', this.barMenuId)
      fD.append('name', this.categoryName)
      this.axios.post('barMenu/addBarCategory', fD)
          .then(r => {
            console.log(r)
            if(r.status === 250){
              _this.categoryName = ''
              _this.categories = r.data.sort((a, b) => {return a.listOrder - b.listOrder})
            }
          })
    },
    deleteCategory(categoryId){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barCategoryId', categoryId)
      this.axios.post('barMenu/deleteBarCategory', fD)
          .then(r => {
            if(r.status === 250){
              _this.categories =_this.categories.filter(c => {
                return c.id !== categoryId
              })
            }
          })
    },
    editCategory(categoryId, name){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barCategoryId', categoryId)
      fD.append('name', name)
      this.axios.post('barMenu/editBarCategory', fD)
          .then(r => {
            if(r.status === 250){
              _this.categories.forEach(c => {
                if(c.id === categoryId){
                  c.name = name
                }
              })
            }
          })
    },
    listOrder(index, moveUp){
      for(let i = 0; i < this.categories.length; i++ ){
        if(!moveUp && i === index){
          this.categories[i].listOrder = i + 1;
        }else if(!moveUp && (i - 1) === index){
          this.categories[i].listOrder = i - 1;
        }else if(moveUp && (i + 1) === index){
          this.categories[i].listOrder = i + 1;
        }else if(moveUp && i === index){
          this.categories[i].listOrder = i - 1;
        }else{
          this.categories[i].listOrder = i;
        }
      }
      this.categories = this.categories.sort((a, b) => {return a.listOrder - b.listOrder})

      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barCategories', JSON.stringify(this.categories))
      this.axios.post('barMenu/changeOrderOfBarCategories', fD)
    },
    deleteBarMenu(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('barMenuId', this.barMenuId)
      this.axios.post('barMenu/deleteBarMenu', fD)
          .then(r => {
            console.log(r.data, r.status)
            if(r.status === 250){
              _this.$router.push('/barMenu?feedback=deleteSuccess')
            }
          })
    }
  }
}
</script>

<style scoped>


</style>