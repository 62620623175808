<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4" id="orders">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/orders" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Bestellungen</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
              </div>
            </div>
          </div>
          <div>
            <section v-if="orderPage === 'overview'">
              <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-2 xl:gap-x-8">
                <li class="bg-blue-100 rounded-md shadow-md px-4 py-8 text-center">
                  <svg class="h-10 w-10 mx-auto" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29 18.18v-2.84A3.35 3.35 0 0 0 25.66 12h-7.32A3.34 3.34 0 0 0 16 13a3.34 3.34 0 0 0-2.34-1H6.34A3.35 3.35 0 0 0 3 15.34v2.84A3 3 0 0 0 1 21v7a1 1 0 0 0 1 1h1v1a1 1 0 0 0 2 0v-1h22v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 1-1v-7a3 3 0 0 0-2-2.82ZM18.34 14h7.32A1.34 1.34 0 0 1 27 15.34v2.83h-.07a2.86 2.86 0 0 0-.43.21l-.15.08a2.68 2.68 0 0 0-.49.4l-.09.12a2.88 2.88 0 0 0-.3.39l-.11.19a2.2 2.2 0 0 0-.17.39.61.61 0 0 0-.05.12h-.08a2.39 2.39 0 0 0-.66-.1H17v-4.63A1.34 1.34 0 0 1 18.34 14ZM7 22.6a.6.6 0 0 1 .6-.6H15v1H7Zm10-.6h7.4a.6.6 0 0 1 .6.6v.4h-8ZM5 15.34A1.34 1.34 0 0 1 6.34 14h7.32A1.34 1.34 0 0 1 15 15.34V20H7.6a2.84 2.84 0 0 0-.66.09h-.07v-.11a3.89 3.89 0 0 0-.19-.42l-.09-.17a2.93 2.93 0 0 0-.41-.52 2.74 2.74 0 0 0-.5-.4s-.12-.06-.17-.1a3.49 3.49 0 0 0-.39-.19h-.07ZM3 21a1 1 0 0 1 1-1 1 1 0 0 1 .71.31A.93.93 0 0 1 5 21v6H3Zm4 4h18v2H7Zm22 2h-2v-6a1 1 0 0 1 1-1 1 1 0 0 1 .71.31.93.93 0 0 1 .29.69Z" data-name="Layer 2" fill="#9ca3af" class="fill-000000"></path>
                  </svg>
                  <h3 class="font-bold text-2xl text-gray-600">
                    Lounge Bestellungen
                  </h3>
                  <button @click="orderPage = 'lounges'" class="mx-auto mt-4 h-10 items-center justify-center rounded-lg px-6 text-sm font-semibold bg-blue-600 text-white hover:bg-blue-700">
                    Auswählen
                  </button>
                </li>
                <li class="bg-blue-100 rounded-md shadow-md px-4 py-8 text-center">
                  <svg class="h-10 w-10 mx-auto" viewBox="0 0 37.47 48.04" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2">
                    <path d="M29.42 0a8 8 0 0 0-7.79 6H1.35A1.36 1.36 0 0 0 .22 8.1l14 21.82v15.42H9.35a1.35 1.35 0 0 0 0 2.7H21a1.35 1.35 0 0 0 0-2.7h-4V29.92l9.31-14.47A8 8 0 1 0 29.42 0Zm-6.67 8.72h4.64l-2.6 4H6.41l-2.59-4Zm-14.6 6.72h14.91L15.6 27Zm21.27-2a5.55 5.55 0 0 1-1.67-.26c.72-1.12 2.5-3.9 3.24-5A1.36 1.36 0 0 0 29.86 6h-5.4C26.65.65 34.74 2.18 34.77 8a5.36 5.36 0 0 1-5.35 5.4Z" data-name="Layer 1" fill="#9ca3af" class="fill-353535"></path></g>
                  </svg>
                  <h3 class="font-bold text-2xl text-gray-600">
                    Bar Bestellungen
                  </h3>
                  <button @click="orderPage = 'bars'" class="mx-auto mt-4 h-10 items-center justify-center rounded-lg px-6 text-sm font-semibold bg-blue-600 text-white hover:bg-blue-700">
                    Auswählen
                  </button>
                </li>
              </ul>
            </section>
            <section v-else-if="orderPage === 'lounges'">
              <section>
                <button @click="orderPage = 'overview'" type="button" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Zurück zur Auswahl
                </button>
              </section>
              <div>
                <form>
                  <div class="relative mt-4 mb-4">
                    <label for="eventId" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Event auswählen</label>
                    <select v-model="eventId" id="eventId" class="text-l px-2 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                      <option :value="null">
                        Bitte Event auswählen
                      </option>
                      <option v-for="event in events" :key="event.id" :value="event.id">
                        {{ event.title }}
                      </option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="bg-white rounded p-4 mt-4 shadow">
                <loader v-if="loading" />
                <div v-show="!loading">
                  <div class="orderInformation">
                    <h2 class="font-bold">Offene Bestellungen</h2>
                    <div class="text-gray-700 text-sm" v-if="!orders.filter(o => o.status === 'open').length">
                      Keine offene Bestellungen
                    </div>
                  </div>
                  <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                    <order-comp-lounges v-for="order in orders.filter(o => o.status === 'open')" :key="order.id" :order="order" @fulfill="fulfill" />
                  </ul>
                </div>
              </div>
              <div class="bg-white rounded p-4 mt-4 shadow">
                <loader v-if="loading" />
                <div v-show="!loading">
                  <div class="orderInformation">
                    <h2 class="font-bold">Bestellung zur Lounge Lieferung</h2>
                    <div class="text-gray-700 text-sm" v-if="!orders.filter(o => o.status === 'ready').length">
                      Keine Bestellungen zur Lounge Lieferung
                    </div>
                  </div>
                  <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                    <order-comp-lounges v-for="order in orders.filter(o => o.status === 'ready')" :key="order.id" :order="order" @fulfill="fulfill" />
                  </ul>
                </div>
              </div>
              <div class="bg-white rounded p-4 mt-4 shadow">
                <loader v-if="loading" />
                <div v-show="!loading">
                  <div class="orderInformation">
                    <h2 class="font-bold">Abgeschlossene Bestellungen</h2>
                    <div class="text-gray-700 text-sm" v-if="!orders.filter(o => o.status === 'fulfilled').length">
                      Keine abgeschlossene Bestellungen
                    </div>
                  </div>
                  <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                    <order-comp-lounges v-for="order in orders.filter(o => o.status === 'fulfilled')" :key="order.id" :order="order" @fulfill="fulfill" />
                  </ul>
                </div>
              </div>
            </section>
            <section v-else-if="orderPage === 'bars'">
              <section>
                <button @click="orderPage = 'overview'" type="button" class="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Zurück zur Auswahl
                </button>
              </section>
              <div>
                <form>
                  <div class="relative mt-4 mb-4">
                    <label for="eventId" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Bar auswählen</label>
                    <select v-model="barsId" id="barsId" class="text-l px-2 block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                      <option :value="null">
                        Bitte Bar auswählen
                      </option>
                      <option v-for="bar in bars" :key="bar.id" :value="bar.id">
                        {{ bar.title }}
                      </option>
                    </select>
                  </div>
                </form>
              </div>
              <div class="bg-white rounded p-4 mt-4 shadow">
                <loader v-if="loading" />
                <div v-show="!loading">
                    <div class="orderInformation">
                      <h2 class="font-bold">Offene Bestellungen</h2>
                      <div class="text-gray-700 text-sm" v-if="!barOrders.filter(o => o.status === 'open').length">
                        Keine offene Bestellungen
                      </div>
                    </div>
                    <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                      <order-comp v-for="order in barOrders.filter(o => o.status === 'open')" :key="order.id" :order="order" @fulfill="fulfill" />
                    </ul>
                </div>
              </div>
              <div class="bg-white rounded p-4 mt-4 shadow">
                <loader v-if="loading" />
                <div v-show="!loading">
                  <div class="orderInformation">
                    <h2 class="font-bold">Bestellung zur Abholung</h2>
                    <div class="text-gray-700 text-sm" v-if="!barOrders.filter(o => o.status === 'ready').length">
                      Keine Bestellungen zur Abholung
                    </div>
                  </div>
                  <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                    <order-comp v-for="order in barOrders.filter(o => o.status === 'ready')" :key="order.id" :order="order" @fulfill="fulfill" />
                  </ul>
                </div>
              </div>
              <div class="bg-white rounded p-4 mt-4 shadow">
                <loader v-if="loading" />
                <div v-show="!loading">
                  <div class="orderInformation">
                    <h2 class="font-bold">Abgeschlossene Bestellungen</h2>
                    <div class="text-gray-700 text-sm" v-if="!barOrders.filter(o => o.status === 'fulfilled').length">
                      Keine abgeschlossene Bestellungen
                    </div>
                  </div>
                  <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                    <order-comp v-for="order in barOrders.filter(o => o.status === 'fulfilled')" :key="order.id" :order="order" @fulfill="fulfill" />
                  </ul>
                </div>
              </div>
            </section>

          </div>
        </div>
      </main>
    </div>
  </div>

</template>

<script>
import Loader from "@/components/basic/loader";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";
import OrderComp from "@/components/order/orderComp";
import OrderCompLounges from "@/components/order/orderCompLounges";
import {notificationStore} from "@/store/notificationStore";

export default {
  name: "orderView",
  components: {OrderComp, OrderCompLounges, Loader},
  setup() {
    const auth = authStore()
    const config = configStore()
    const notifications = notificationStore()
    const user = userStore()

    return {auth, config, notifications, user}
  },
  title: "Bestellungen",
  data(){
    return{
      orders: [],
      barOrders: [],
      events: [],
      bars: [],
      barsId: null,
      eventId: null,
      loading: true,
      interval: null,
      interval2: null,
      orderPage: 'overview'
    }
  },
  watch: {
    eventId(){
      this.loadOrders()
    },
    barsId(){
      this.loadBarOrders()
    }
  },
  mounted() {
    this.loadEvents()
    this.loadBars()
    this.notifications.pushToken()
    this.notifications.$subscribe(() => {
      this.loadOrders()
      this.loadBarOrders()
    })
  },
  unmounted() {
    clearInterval(this.interval)
    clearInterval(this.interval2)

  },
  methods: {
    loadEvents(){
      this.loading = true
      aSyncData('orders/getEvents', {organizerId: this.config.organizerId})
          .then(r => {
            if(r.status === 250) {
              this.events = r.data
              if(this.events.length) {
                this.eventId = this.events[0].id
                this.interval = setInterval(() => this.loadOrders(), 5*1000)
              }else{
                this.loading = false
              }
            }
          })
          .catch(e => {
            console.error(e)
            this.loading = false
          })
    },
    loadBars(){
      this.loading = true
      aSyncData('bars/getTemplates', {organizerId: this.config.organizerId})
          .then(r => {
            console.log(r)
            if(r.status === 250) {
              this.bars = r.data
              if(this.bars.length) {
                this.barsId = this.bars[0].id
                this.interval2 = setInterval(() => this.loadBarOrders(), 5*1000)
              }else{
                this.loading = false
              }
            }
          })
          .catch(e => {
            console.error(e)
            this.loading = false
          })
    },
    loadOrders(){
      if(!this.eventId) return
      aSyncData('orders/getOrders', {eventId: this.eventId})
          .then(r => {
            if(r.status === 250) this.orders = r.data
          })
          .catch(e => {console.error(e)})
          .finally(() => {this.loading = false})
    },
    loadBarOrders(){
      if(!this.barsId) return
      aSyncData('orders/getBarOrders', {barId: this.barsId})
          .then(r => {
            if(r.status === 250) this.barOrders = r.data
          })
          .catch(e => {console.error(e)})
          .finally(() => {this.loading = false})
    },
    loadOrder(orderId){
      aSyncData('orders/getOrder', {orderId: orderId})
          .then(r => {
            if(r.status === 250 && o.eventId === this.eventId) {
              if(!this.orders.some(o => o.id === r.data.id)){
                this.orders.push(r.data)
              }
            }
          })
          .catch(e => {console.error(e)})
    },
    fulfill(id){
      const date = new Date()
      let order = this.orders.find(o => o.id === id)
      order.status = 'fulfilled'
      order.delivered = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    }
  }
}
</script>

<style scoped>


.orderInformation{
  margin: 16px 0 12px;
  font-size: 1.4em;
  font-weight: 400;
  text-align: center;
}

</style>