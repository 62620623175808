import { createRouter, createWebHistory } from 'vue-router'

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {socketStore} from "@/store/socketStore";

import profil from "@/views/profil";
import login from "@/views/login";
import dashboard from "@/views/dashboard";
import demoregister from "@/views/demoregister";


import customerInfo from "@/views/customerInformation/customerInformation";
import customerInformationParentView from "@/views/customerInformationParentView";
import userInfo from "@/views/customerInformation/userInfo";

import clubmanager from "@/views/clubmanager";
import taxizentrale from "@/views/taxizentrale";
import checkin from "@/views/checkin";
import fundgrube from "@/views/fundgrube";
import admanager from "@/views/admanager";
import feedback from "@/views/feedback";
import usermanagement from "@/views/usermanagement";
import ticketcenter from "@/views/ticketcenter";
import punktesystem from "@/views/punktesystem";
import muttizettel from "@/views/muttizettel";


import navBar from "@/components/navigation/NavBar";
import sideBar from "@/components/navigation/SideBar";
import footer from "@/components/footer/footer";

import events from "@/views/events";
import eventOverview from "@/views/events/overview";
import eventCreate from "@/views/events/create";
import eventEdit from "@/views/events/edit";
import eventMedia from "@/views/events/media";

import ticketOverview from "@/views/ticketcenter/overview";
import ticketsales from "@/views/ticketcenter/ticketsales";
import ticketCreate from "@/views/ticketcenter/create";
import ticketEdit from "@/views/ticketcenter/edit";
import eventlyPay from "@/views/eventlyPay";
import scanner from "@/views/ticketcenter/scanner";

import barMenu from "@/views/barMenu";
import barMenuDetails from "@/views/barMenu/barMenuDetails";
import barMenuOverview from "@/views/barMenu/barMenuOverview";
import bars from "@/views/bars";

import lounges from "@/views/lounges";
import notificationService from "@/views/notificationService";
import manual from "@/views/notificationService/manual";
import birthday from "@/views/notificationService/birthday";
import couponsParentView from "@/views/coupons/couponsParentView";
import couponsOverviewView from "@/views/coupons/couponsOverviewView";
import couponView from "@/views/coupons/CouponView";
import snippetsView from "@/views/snippetsView";
import orderView from "@/views/orderView";
import slotPlaner from "@/views/slotPlaner.vue";
import slotOverview from "@/views/slotOverview.vue";
import garderobe from "@/views/garderobe.vue";
import universalAlbumsParentView from "@/views/universalAlbums/universalAlbumsParentView.vue";
import universalAlbumsView from "@/views/universalAlbums/universalAlbumsView.vue";
import universalAlbumDetailView from "@/views/universalAlbums/universalAlbumDetailView.vue";
import loungescanner from "@/views/loungescanner.vue";

const routes = [
  {
    path: '/',
    alias: '/login',
    name: 'Login',
    components: {
      default: login
    },
    meta: { noAuth: true },
  },
  {
    path: '/demoregister',
    alias: '/demoregister',
    name: 'DemoRegister',
    components: {
      default: demoregister
    },
    meta: { noAuth: true },
  },
  {
    path: '/profil',
    name: 'Profil',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: profil,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: dashboard,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/garderobe',
    name: 'Garderobe',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: garderobe,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/slotplaner',
    name: 'Schichtplaner',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: slotPlaner,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/slotoverview',
    name: 'Slotoverview',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: slotOverview,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/orders',
    alias: '/bestellungen',
    name: 'Orders',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: orderView,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/customerInfo',
    alias: '/kundeninfo',
    name: 'CustomerInfoParent',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: customerInformationParentView,
      footer: footer
    },
    children: [
      {
        path: '',
        name: 'CustomerInfo',
        component: customerInfo
      },
      {
        path: ':userId',
        name: 'Customer',
        component: userInfo,
        props: true
      }
    ],
    meta: { auth: true },
  },
  {
    path: '/coupons',
    name: 'CouponsParent',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: couponsParentView,
      footer: footer
    },
    children: [
      {
        path: '',
        name: 'CouponsOverview',
        component: couponsOverviewView
      },
      {
        path: 'coupon/:couponId?',
        name: 'Coupon',
        component: couponView,
        props: true
      }
    ],
    meta: { auth: true, permission: 'couponManagement' },
  },
  {
    path: '/notificationservice',
    alias: '/nachrichtendienst',
    name: 'NotificationService Parent',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: notificationService,
      footer: footer
    },
    children: [
      {
        path: '',
        name: 'NotificationService',
        component: manual
      },
      {
        path: 'birthday',
        name: 'NotificationService Birthday',
        component: birthday
      }
    ],
    meta: { auth: true, permission: 'notificationManagement'  },
  },
  {
    path: '/lounges',
    name: 'Lounges',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: lounges,
      footer: footer
    },
    meta: { auth: true, permission: 'loungeManagement' }
  },
  {
    path: '/lounges/scanner',
    name: 'LoungeScanner',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: loungescanner,
      footer: footer
    },
    meta: { auth: true, permission: 'loungeManagement' }
  },
  {
    path: '/bars',
    name: 'Bars',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: bars,
      footer: footer
    },
    meta: { auth: true, permission: 'barMenuManagement' }
  },
  {
    path: '/clubmanager',
    name: 'Clubmanager',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: clubmanager,
      footer: footer
    },
    meta: { auth: true, permission: 'clubManagement' },
  },
  {
    path: '/taxizentrale',
    name: 'Taxizentrale',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: taxizentrale,
      footer: footer
    },
    meta: { auth: true, permission: 'clubManagement' },
  },
  {
    path: '/muttizettel',
    name: 'Muttizettel',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: muttizettel,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/checkin',
    name: 'Checkin',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: checkin,
      footer: footer
    },
    meta: { auth: true, permission: 'clubManagement' },
  },
  {
    path: '/fundgrube',
    name: 'Fundgrube',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: fundgrube,
      footer: footer
    },
    meta: { auth: true},
  },
  {
    path: '/admanager',
    name: 'Admanager',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: admanager,
      footer: footer
    },
    meta: { auth: true, permission: 'clubManagement' },
  },
  {
    path: '/feedback',
    name: 'Feedback',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: feedback,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/punktesystem',
    name: 'Punktesystem',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: punktesystem,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/benutzerverwaltung',
    alias: '/usermanagement',
    name: 'Usermanagement',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: usermanagement,
      footer: footer
    },
    meta: { auth: true, permission: 'userManagement' },
  },
  {
    path: '/ticketcenter/:page?',
    name: 'Ticketcenter',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: ticketcenter,
      footer: footer
    },
    children: [
      {
        path: '',
        name: 'TicketOverview',
        component: ticketOverview,
        meta: { permission: 'ticketManagement'}
      },
      {
        path: 'create',
        name: 'TicketCreate',
        component: ticketCreate,
        meta: { permission: 'ticketManagement'}
      },
      {
        path: 'ticketsales',
        name: 'ticketsales',
        component: ticketsales,
        meta: { permission: 'ticketManagement'}
      },
      {
        path: 'edit/:id',
        name: 'TicketEdit',
        component: ticketEdit,
        meta: { permission: 'ticketManagement'}
      },
      {
        path: 'scanner',
        name: 'scanner',
        component: scanner,
        meta: { permission: 'scanTicket'}
      }
    ],
    meta: { auth: true },
  },
  {
    path: '/events/:page?',
    name: 'Events',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: events,
      footer: footer
    },
    children: [
      {
        path: '',
        name: 'EventOverview',
        component: eventOverview
      },
      {
        path: 'create',
        name: 'EventCreate',
        component: eventCreate,
        meta: { permission: 'eventManagement'}
      },
      {
        path: 'edit/:eventId',
        name: 'EventEdit',
        component: eventEdit,
        props: true,
        meta: { permission: 'eventManagement'}
      },
      {
        path: 'media/:eventId',
        name: 'EventMedia',
        component: eventMedia,
        props: true,
        meta: { permission: 'uploadEventMedia'}
      }
    ],
    meta: { auth: true },
  },
  {
    path: '/barMenu/',
    name: 'BarMenu',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: barMenu,
      footer: footer
    },
    children: [
      {
        path: '',
        name: 'BarMenuOverview',
        component: barMenuOverview,
        props: true
      },
      {
        path: ':barMenuId',
        name: 'BarMenuDetails',
        component: barMenuDetails,
        props: true
      },
    ],
    meta: { auth: true, permission: 'barMenuManagement' },
  },
  {
    path: '/eventlyPay',
    name: 'EventlyPay',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: eventlyPay,
      footer: footer
    },
    meta: { auth: true },
  },
  {
    path: '/albums',
    name: 'Albums',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: universalAlbumsParentView,
      footer: footer
    },
    children: [
      {
        path: '',
        name: 'universalMedia',
        component: universalAlbumsView
      },
      {
        path: ':albumId',
        name: 'SingleAlbum',
        component: universalAlbumDetailView,
        props: true
      },
    ],
    meta: { auth: true },
  },
  {
    path: '/snippets',
    name: 'Snippets',
    components: {
      navBar: navBar,
      sideBar: sideBar,
      default: snippetsView,
      footer: footer
    },
  },
  {
    name: '404',
    path: '/:pathMatch(.*)*',
    alias: '/404',
    redirect: () => {
      return { path: '/' }
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()
  const socket = socketStore()

  config.menuModal = false

  window.scrollTo(0, 0)
  window.onscroll = function () {}
  if (to.matched.some(record => record.meta.auth)) {
    auth.update()
        .then(r => {
          socket.connect()
          config.loading = true
          if(!to.meta.permission || (auth.permissions[config.organizerId] && auth.permissions[config.organizerId][to.meta.permission])){
            next()
          }else{
            next({
              name: 'Dashboard'
            })
          }
        })
        .catch(e => {
          console.error(e)
          next({
            path: '/login',
            query: { redirect: encodeURI(to.fullPath) }
          })
        })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    auth.update()
        .then(() => {
          next({
            name: 'Dashboard'
          })
        })
        .catch( e => {
          next()
        })
        .finally(function () {
          config.loading = true
        })
  } else {
    auth.update()
        .catch( e =>{
          console.log(e)
        })
        .finally(() => {
          config.loading = true
          next()
        })
  }
})

let history = []
router.afterEach((to, from) => {
  if(to.path === history.at(-2)?.path){
    to.meta.transition = history.at(-2).direction === 'slide-right' ? 'slide-left' : 'slide-right'
    history.pop()
  }else{
    to.meta.transition = to.path.split('/').length < from.path.split('/').length ? 'slide-right' : 'slide-left'
    history.push({path: to.path, direction: to.meta.transition})
  }
})

export default router
