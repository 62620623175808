<template>
  <div class="bg-neutral-50 h-full">
    <div class="lg:pl-72">
      <main class="py-4" id="bar">
        <div class="px-4">
          <div class="mb-2">
            <div>
              <nav class="hidden sm:flex" aria-label="Breadcrumb">
                <ol role="list" class="flex items-center space-x-4">
                  <li>
                    <div class="flex">
                      <a href="/bars" class="text-sm font-medium text-gray-500 hover:text-gray-700">Übersicht</a>
                    </div>
                  </li>
                </ol>
              </nav>
            </div>
            <div class="mt-2 md:flex md:items-center md:justify-between">
              <div class="min-w-0 flex-1">
                <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">Bars</h2>
              </div>
              <div class="mt-4 flex flex-shrink-0 md:ml-4 md:mt-0">
                <button @click="newModal = !newModal" type="button" class="ml-4 h-10 items-center justify-center rounded-lg px-6 text-sm sm:flex bg-blue-600 font-semibold text-white hover:bg-blue-700">
                  Bar erstellen
                </button>
              </div>
            </div>
          </div>
          <div>
            <div class="bg-white rounded shadow p-4 grid-12">
              <bar v-for="bar in bars" :key="bar.id" :bar="bar" @edit-bar="editBar" @delete-bar="deleteBar" />
              <p v-if="bars?.length === 0" class="col-sp-12 row-sp-12 text-gray-600 text-center text-sm">
                Es wurde noch keine Bar erstellt.
              </p>
            </div>
          </div>
        </div>
      </main>
    </div>
    <TransitionRoot as="template" :show="newModal">
      <Dialog as="div" class="relative z-10" @close="resetBarForm()">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <form @submit.prevent="addBar()">
                  <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" @click="resetBarForm()">
                      <span class="sr-only">Close</span>
                      <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div class="sm:flex sm:items-start">
                    <div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <svg class="h-6 w-6 text-blue-600" viewBox="0 0 37.47 48.04" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><path d="M29.42 0a8 8 0 0 0-7.79 6H1.35A1.36 1.36 0 0 0 .22 8.1l14 21.82v15.42H9.35a1.35 1.35 0 0 0 0 2.7H21a1.35 1.35 0 0 0 0-2.7h-4V29.92l9.31-14.47A8 8 0 1 0 29.42 0Zm-6.67 8.72h4.64l-2.6 4H6.41l-2.59-4Zm-14.6 6.72h14.91L15.6 27Zm21.27-2a5.55 5.55 0 0 1-1.67-.26c.72-1.12 2.5-3.9 3.24-5A1.36 1.36 0 0 0 29.86 6h-5.4C26.65.65 34.74 2.18 34.77 8a5.36 5.36 0 0 1-5.35 5.4Z" data-name="Layer 1" fill="#9ca3af" class="fill-353535"></path></g></svg>
                    </div>
                    <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">Bar {{barTemplateId ? 'bearbeiten' : 'erstellen'}}</DialogTitle>
                    </div>
                  </div>
                  <div>
                    <div>
                      <p class="text-sm text-gray-500 block w-100">
                        {{barTemplateId ? 'Bearbeite eine Bar für deine Location.' : 'Erstelle eine Bar für deine Location.'}}
                      </p>
                    </div>
                    <div>
                      <div class="flex">
                        <div class="pt-4">
                          <cropper-upload :init="false" :ratio="1" @cropped="c => image = c" button-text="Bar-Bild hochladen" />
                        </div>
                        <div class="ml-4 flex-shrink-0 self-center">
                          <img :src="config.projectURL + 'assets/images/lounges/' + oldImage + '.jpg'" v-if="oldImage && !image" class="mt-4 mr-4 rounded w-20">
                        </div>
                      </div>
                      <div class="relative mt-4">
                        <label for="title" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Title</label>
                        <input required type="text" v-model="title" placeholder="Titel" name="title" id="title" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                      </div>
                      <div class="relative mt-4">
                        <label for="description" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Beschreibung</label>
                        <textarea v-model="description" required placeholder="Beschreibung" name="description" id="description" class="px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                      </div>
                      <div class="relative mt-4 rounded-md shadow-sm">
                        <label for="barMenu" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Getränke- / Barkarte auswählen</label>
                        <select v-model="barMenu" name="barMenu" required id="barMenu" class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                          <option :value="null">
                            Keine
                          </option>
                          <option :value="menu.id" v-for="menu in barMenus" :key="menu.id">
                            {{ menu.name }}
                          </option>
                        </select>
                      </div>
                      <div class="relative mt-4 rounded-md shadow-sm">
                        <label for="active" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Ist die Bar geöffnet?</label>
                        <select v-model="active" required name="active" id="active" class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                          <option value="1">
                            Ja
                          </option>
                          <option value="0">
                            Nein
                          </option>
                        </select>
                      </div>
                      <div class="relative mt-4 rounded-md shadow-sm">
                        <label for="payment" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900">Zahlungsmöglichkeiten</label>
                        <select v-model="payment" required name="payment" id="payment" class="px-2 text-l block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6">
                          <option value="1">
                            App & Barzahlung
                          </option>
                          <option value="2">
                            Nur Barzahlung
                          </option>
                          <option value="3">
                            Nur Zahlung per App
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button :disabled="loading" type="submit" class="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto">{{loading ? 'Bitte warten' : barTemplateId ? 'Änderungen speichern' : 'Bar erstellen'}}</button>
                    <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto" @click="resetBarForm()">Abbrechen</button>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <notification class="my-4" v-if="successDelete" type="success" title="Bar gelöscht" message="Die Bar wurde erfolgreich gelöscht."/>
    <notification class="my-4" v-if="saveMessage" type="success" title="Speichern erfolgreich" message="Die Änderungen wurden erfolgreich übernommen."/>
  </div>
</template>

<script>
/* eslint-disable */
import {aSyncData} from '@/modules/main.js'
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import Bar from "@/components/bars/bar";
import CropperUpload from "@/components/images/cropperUpload";
import { ChevronLeftIcon, ChevronRightIcon, CurrencyEuroIcon, TicketIcon, QrCodeIcon, CheckCircleIcon, ClockIcon, ArrowDownIcon, ArrowUpIcon,   BriefcaseIcon, CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon, ShoppingBagIcon, TagIcon, UserGroupIcon } from '@heroicons/vue/20/solid'
import notification from "@/components/notifications/notification";
import alert from "@/components/alerts/alert";
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'bars',
  components: {
    Bar,
    CropperUpload,
    ChevronLeftIcon, ChevronRightIcon, TicketIcon, CurrencyEuroIcon, QrCodeIcon,
    notification,alert,RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption,
    CheckCircleIcon, ClockIcon,ArrowDownIcon, ArrowUpIcon,  BriefcaseIcon,
    CalendarIcon, EnvelopeIcon, ChevronDownIcon, LinkIcon, MapPinIcon, PencilSquareIcon, BanknotesIcon,
    Menu, MenuButton, MenuItem, MenuItems, Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot,
    ExclamationTriangleIcon, XMarkIcon, ShoppingBagIcon, TagIcon, UserGroupIcon
    },
  title: 'Bars',
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      bars: [],
      newModal: false,
      barTemplateId: 0,
      title: '',
      description: '',
      barMenu: null,
      image: null,
      oldImage: null,
      successDelete: false,
      saveMessage: false,
      barMenus: [],
      active: 1,
      payment: 1,
      loading: false
    }
  },
  mounted() {
    this.getBars()
    this.getBarMenus()

  },
  methods: {
    getBarMenus(){
      aSyncData('barMenu/getBarMenues', {})
          .then(r => {
            if(r.status === 250){
              this.barMenus = r.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getBars() {
      const _this = this
      aSyncData('bars/getTemplates', {organizerId: this.config.organizerId})
          .then(r => {
            console.log(r.data)
            if (r.status === 250) {
              _this.bars = r.data
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    addBar() {
      this.loading = true
      this.saveMessage = false
      const _this = this
      aSyncData(this.barTemplateId ? 'bars/editTemplate' : 'bars/addTemplate', {
        organizerId: this.config.organizerId,
        barTemplateId: this.barTemplateId,
        title: this.title,
        description: this.description,
        barMenu: this.barMenu,
        barImage: this.image,
        active: this.active,
        payment: this.payment
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(r => {
            console.log(r)
            if (r.status === 250) {
              _this.bar = r.data
              _this.saveMessage = true
              _this.getBars()
              _this.resetBarForm()
              _this.loading = false
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    editBar(bar){
      this.barTemplateId = bar.id
      this.title = bar.title
      this.description = bar.description
      this.barMenu = bar.barMenuId
      this.newModal = true
      this.oldImage = bar.image
      this.active = bar.active
      this.payment = bar.payment
      this.loading = false
    },
    resetBarForm(){
      this.newModal = false
      this.barTemplateId = 0
      this.title = ''
      this.description = ''
      this.barMenu = null
      this.image = null
      this.oldImage = null
      this.active = 1
      this.payment = 1
      this.loading = false
    },
    deleteBar(barTemplateId){
      this.successDelete = false
      aSyncData('bars/deleteTemplate', {
        organizerId: this.config.organizerId,
        barTemplateId: barTemplateId,
      })
          .then(r => {
            console.log(r)
            if (r.status === 250) {
              this.successDelete = true
              this.getBars()
              this.resetBarForm()
            }
          })
          .catch(e => {
            console.log(e)
          })
    }
  }
}
</script>

<style scoped>



</style>
